import React from 'react';
import Form from  '../components/Form';


export default function Confirm(props) {
    return <Form form={ FORMITEMLIST }/>
}

//立即报名
const FORMITEMLIST = {
  type:'confirm',tabs:[
    {label:'登录',type:'login'},
    {label:'密码找回',type:'confirm'},
  ],
  list:[
    {label:'新密码',placeholder:'请输入密码',required:true,name:'newPassword',type:'input',type2:'password'},
    {label:'确认新密码',placeholder:'请输入密码',required:true,name:'password',type:'input',type2:'password'},
    {label:'',name:"nolink",type:'link'},
    {label:'确定',name:'confirmPassword',type:'button',type2:'resetConfirm'},
    {label:'请使用RAYS编辑账号登录'},
    {label:'如暂无RAYS编辑账号，请联系15926459426（雷老师）进行开通。'}
  ]
}